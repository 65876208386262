const About = () => {
    return (
        <section className="about-wrapper" id="about">
            <h2>OM OSS</h2>
            <p className="about-text">Idén till Forca Fighting växte fram år 2005 när en tävlingsgrupp på forna kampsportsklubben SBJJA ville träna oftare och hårdare. Med bröderna Visar och Sadri Kelmendi i spetsen hyrde man in sig på Solna Judoklubb där likasinnade träffades för att träna MMA på avancerad nivå. I takt med att allmänhetens intresse för sporten tilltog började man hålla träningar för alla nivåer. Verksamheten växte och föreningen Forca Fighting startades.</p>
            <p className="about-text about-text-bottom">Under åren som gått har föreningen vuxit sig allt större och har under tiden skördat stora framgångar på de svenska och internationella kampsportsscenerna. Med flertalet vunna svenska mästerskap i sporterna submission wrestling, shootfighting och MMA har klubben byggt upp svenska landslagsmän och professionella MMA-utövare. Forca Fighting håller även mycket uppskattade träningar för motionärer, nybörjare och barn/ungdomar. I januari 2022 öppnade vi ytterligare en klubb i Milot, Ursvik och vi finns därmed både i Solna och Sundbyberg norr om Stockholm. Klubben i Ursvik är dock stängd våren 2025.</p>
        </section>
    );
};

export default About;