import React from 'react';
import ClubStartpage from './ClubStartpage';
// import About from './About';
import Schedule from './Schedule';
import Contact from './Contact';
import Prices from './Prices';
import Rules from './Rules';

interface Activity {
    day: string,
    time: string,
    type: string,
    room: string,
    grade: string,
    key: number
}
interface ClubContact {
    club: string,
    mail: string,
    tel: string,
    altTel: string,
    address: string
}
interface IPrice {
    article: string,
    price: string,
}
interface Props {
    contactInfo: ClubContact[],
}

const Solna = (props: Props) => {
    const days: string[] = ["Mån", "Ons", "Fre", "Lör", "Sön"];
    const activities: Activity[] = [
        {day: "Mån", time: "19.30-20.45", type: "SW*/MMA**", room: "", grade: "Nybörjare", key: 1},
        {day: "Mån", time: "20.00-21.30", type: "SW*/MMA**", room: "", grade: "Avancerad", key: 2},
        {day: "Ons", time: "19.30-20.45", type: "SW*/Brottning", room: "", grade: "Nybörjare", key: 3},
        {day: "Ons", time: "20.00-21.30", type: "SW*/Brottning", room: "", grade: "Avancerad", key: 4},
        {day: "Fre", time: "18.00-19.30", type: "Thaiboxning", room: "", grade: "Nybörjare", key: 5},
        {day: "Fre", time: "19.30-21.00", type: "Thaiboxning", room: "", grade: "Avancerad", key: 6},
        {day: "Lör", time: "13.00-14.15", type: "Brottning", room: "", grade: "Barnträning 7-13 år", key: 7},
        {day: "Lör", time: "15.00-16.30", type: "Boxning", room: "", grade: "Blandat", key: 8},
        {day: "Sön", time: "12.00-13.30", type: "MMA**", room: "", grade: "Nybörjare", key: 9},
        {day: "Sön", time: "13.30-15.00", type: "MMA**", room: "", grade: "Avancerad", key: 10},
    ];
    // const activities: Activity[] = [
    //     {day: "Mån", time: "19.30-21.00", type: "SW*", room: "", grade: "", key: 1},
    //     {day: "Ons", time: "19.00-20.30", type: "SW*/Brottning", room: "", grade: "", key: 3},
    //     {day: "Fre", time: "19.00-20.30", type: "Boxning/Thaiboxning", room: "", grade: "", key: 5},
    // ];
    const prices: IPrice[] = [
        {article: "Terminsavgift", price: "2400"},
        {article: "Terminsavgift barn", price: "1200"},
        {article: "Månadsträning", price: "950"},
        {article: "Årskort", price: "5000"},
        {article: "Träning 1 gång", price: "180"},
        {article: "Klippkort 10ggr", price: "1500"},
        {article: "Grupp svensexa/kickoff", price: "2000"},
        {article: "Privatlektion 1 person medlem", price: "500"},
        {article: "Privatlektion 2 personer medlemmar", price: "700"},
        {article: "Privatlektion 3-10 personer medlemmar", price: "1000"},
        {article: "Privatlektion 1 person icke medlem", price: "700"},
        {article: "Privatlektion 2 personer icke medlemmar", price: "1000"},
        {article: "Privatlektion 3-10 personer icke medlemmar", price: "1500"},
    ];

    return (
        <div>
            <ClubStartpage club="solna"/>
            {/* <About/> */}
            <Schedule days={days} activities={activities} ursvik={false}/>
            <Rules/>
            <Prices prices={prices} ursvik={false}/>
            <Contact contactInfo={props.contactInfo} club="solna"/>
        </div>
    );
};

export default Solna;