import { Link } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';
import { useLocation } from 'react-router-dom';

interface Props {
    setShowMenu: (param: boolean) => void
}
const MobileMenu = (props: Props) => {
    const {pathname} = useLocation();
    const onStartPage: boolean = pathname === '/';

    return (
        <nav className="mobile-menu">
            <ul>
                {onStartPage ?
                    null
                :
                    <li><Link to="/" className="nav-link" onClick={() => props.setShowMenu(false)}>&lt; Startsida</Link></li>
                }
                <li onClick={() => props.setShowMenu(false)}><Link to="/solna" className="nav-link">Solna</Link></li>

                

                {onStartPage ?
                    <>
                    <li><ScrollLink to="about" spy={true} smooth={true} className='nav-link' onClick={() => props.setShowMenu(false)}>Om oss</ScrollLink></li>
                    {/* <li><ScrollLink to="pictures" spy={true} smooth={true} className='nav-link' onClick={() => props.setShowMenu(false)}>Bilder</ScrollLink></li> */}
                    <li><ScrollLink to="styles" spy={true} smooth={true} className='nav-link' onClick={() => props.setShowMenu(false)}>Stilar</ScrollLink></li>
                    <li><ScrollLink to="trainers" spy={true} smooth={true} className='nav-link' onClick={() => props.setShowMenu(false)}>Tränare</ScrollLink></li>
                    <li><ScrollLink to="rules" spy={true} smooth={true} className='nav-link' onClick={() => props.setShowMenu(false)}>Viktig info</ScrollLink></li>
                    </>
                :
                    <>
                    <li><ScrollLink to="schedule" spy={true} smooth={true} className='nav-link' onClick={() => props.setShowMenu(false)}>Schema</ScrollLink></li>
                    <li><ScrollLink to="rules" spy={true} smooth={true} className='nav-link' onClick={() => props.setShowMenu(false)}>Viktig info</ScrollLink></li>
                    <li><ScrollLink to="prices" spy={true} smooth={true} className='nav-link' onClick={() => props.setShowMenu(false)}>Priser</ScrollLink></li>
                    </>
                }

                <li><ScrollLink to="contact" spy={true} smooth={true} className='nav-link' onClick={() => props.setShowMenu(false)}>Kontakt</ScrollLink></li>
            </ul>
        </nav>
    );
};

export default MobileMenu;