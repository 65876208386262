import ScheduleWrapper from './Schedule/ScheduleWrapper'

interface Props {
    days: string[],
    activities: Activity[],
    ursvik: boolean,
}
interface Activity {
    day: string,
    time: string,
    type: string,
    room: string,
    grade: string,
    key: number
}

const Schedule = (props: Props) => {
    return (
        <section className="schedule" id="schedule">
            <h2>SCHEMA</h2>
            <h3>Vårterminen 2025</h3>
            <h4>Gäller fr.o.m. {props.ursvik ? `27/8` : `13/1`}</h4>
            <ScheduleWrapper days={props.days} activities={props.activities} ursvik={props.ursvik}/>
        </section>
    );
};

export default Schedule;