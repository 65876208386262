interface Props {
    setShowNews: (boolean: boolean) => void,
}

const News = (props: Props) => {
    return (
        <section className='news-backdrop'>
            <article className="news">
                <button className='close-news' onClick={() => props.setShowNews(false)}>X</button>
                <h2>Gott nytt år 2025!</h2>
                <h3>Vårterminen börjar 13/1</h3>
                <div>
                    <p>Under våren kommer träningspass endast att ges i Solna. <span>Klubben i Ursvik är tillsvidare stängd och inga pass kommer att ges där under vårterminen.</span></p>
                    <p>Vi har uppdaterat våra priser för samtliga pass. Du finner dem under Solnas klubbsida</p>
                    <p>Välkomna!</p>
                </div>
            </article>
        </section>
    );
};

export default News;